import * as React from "react";
import * as T from "types";
import * as Ui from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { fetchObjectContent } from "api";
import { MapViz } from "components/MapViz";
import { PkgTable } from "./PkgTable";
import { ChooseRel } from "./ChooseRels";
import { BucketLink } from "components/BucketLink";

interface Props {
  allObjects: T.StorageMetadataList;
  accessToken: string;
  pkgFamilyFiles?: T.PkgFamilyFilesMap;
}

interface UrlParams {
  channel_old: string;
  version_old: string;
  channel_new: string;
  version_new: string;
}

export const ReleaseDiff: React.FC<Props> = (p) => {
  // Init state
  const params = useParams() as any as UrlParams;
  const [relMeta_old, setRelMeta_old] =
    React.useState<T.ReleaseStorageMetadata>();
  const [relFile_old, setRelFile_old] = React.useState<T.RelFile>();

  const [relMeta_new, setRelMeta_new] =
    React.useState<T.ReleaseStorageMetadata>();
  const [relFile_new, setRelFile_new] = React.useState<T.RelFile>();

  // Find versions
  const version_old =
    params.version_old === "latest"
      ? T.findLatestVer(p.allObjects, params.channel_old)
      : parseInt(params.version_old);

  const version_new =
    params.version_new === "latest"
      ? T.findLatestVer(p.allObjects, params.channel_new)
      : parseInt(params.version_new);

  // Find release files metadata
  React.useEffect(() => {
    setRelMeta_old(
      T.filterReleases(p.allObjects).find(
        (r) =>
          r.fkChannel === params.channel_old &&
          r.fkReleaseVersion === version_old
      )
    );
    setRelMeta_new(
      T.filterReleases(p.allObjects).find(
        (r) =>
          r.fkChannel === params.channel_new &&
          r.fkReleaseVersion === version_new
      )
    );
  }, [p.allObjects, params, version_old, version_new]);

  // Download release files
  React.useEffect(() => {
    if (!relMeta_old) {
      return;
    }
    fetchObjectContent(p.accessToken, relMeta_old).then(setRelFile_old);
  }, [relMeta_old, p.accessToken]);

  React.useEffect(() => {
    if (!relMeta_new) {
      return;
    }
    fetchObjectContent(p.accessToken, relMeta_new).then(setRelFile_new);
  }, [relMeta_new, p.accessToken]);

  if (!relMeta_old || !relFile_old) {
    return (
      <div>
        <ChooseRel allObjects={p.allObjects} />
        <span>Old release not found</span>;
      </div>
    );
  }
  if (!relMeta_new || !relFile_new) {
    return (
      <div>
        <ChooseRel allObjects={p.allObjects} />
        <span>New release not found</span>;
      </div>
    );
  }

  return (
    <div>
      <ChooseRel allObjects={p.allObjects} />
      <Ui.Grid>
        <Ui.Grid.Row>
          <Ui.Grid.Column width={8}>
            <Ui.Header>Metadata old</Ui.Header>
            <MapViz data={{ "Release date": relMeta_old.timeCreated }} />
            <BucketLink fileName={relMeta_old.name} />
          </Ui.Grid.Column>
          <Ui.Grid.Column width={8}>
            <Ui.Header>Metadata new</Ui.Header>
            <MapViz data={{ "Release date": relMeta_new.timeCreated }} />
            <BucketLink fileName={relMeta_new.name} />
          </Ui.Grid.Column>
        </Ui.Grid.Row>
      </Ui.Grid>

      <Ui.Header>Jetson packages</Ui.Header>
      <PkgTable
        accessToken={p.accessToken}
        allObjects={p.allObjects}
        pkgFamilyFiles={p.pkgFamilyFiles}
        packages_old={relFile_old.packages}
        packages_new={relFile_new.packages}
        showDiffColumn={true}
      />

      <Ui.Header>File packages</Ui.Header>
      <PkgTable
        accessToken={p.accessToken}
        allObjects={p.allObjects}
        pkgFamilyFiles={p.pkgFamilyFiles}
        packages_old={relFile_old.tabletPackages}
        packages_new={relFile_new.tabletPackages}
      />

      <Ui.Header>Api packages</Ui.Header>
      <PkgTable
        accessToken={p.accessToken}
        allObjects={p.allObjects}
        pkgFamilyFiles={p.pkgFamilyFiles}
        packages_old={relFile_old.apiPackages}
        packages_new={relFile_new.apiPackages}
      />
    </div>
  );
};

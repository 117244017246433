import * as React from "react";
import { useParams, Navigate } from "react-router-dom";

interface Props {}

interface UrlParams {
  channel: string;
  version: string;
}

export const Release: React.FC<Props> = (p) => {
  const params = useParams() as any as UrlParams;

  return (
    <Navigate
      replace
      to={`/release/${params.channel}/${params.version}/${params.channel}/${params.version}`}
    />
  );
};

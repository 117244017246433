import * as React from "react";
import * as T from "types";
import * as Ui from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { CopyableText } from "./CopyableText";

interface Props {
  allObjects: T.StorageMetadataList;
}

interface UrlParams {
  channel_old: string;
  version_old: string;
  channel_new: string;
  version_new: string;
}

export const ChooseRel: React.FC<Props> = (p) => {
  // Init state
  const params = useParams() as any as UrlParams;
  const navigate = useNavigate();

  const updateUrl = (newParams: UrlParams) => {
    navigate(
      `/release/${newParams.channel_old}/${newParams.version_old}/${newParams.channel_new}/${newParams.version_new}`
    );
  };

  const channelOptions = Array.from(
    T.groupChannels(p.allObjects).map((group) => {
      const channel = group.first(null)?.fkChannel;
      return {
        key: channel,
        value: channel,
        text: channel,
      };
    })
  );

  return (
    <div>
      <Ui.Form>
        <Ui.Form.Group>
          <Ui.Form.Field inline>
            <Ui.Label color="red">
              From
              <CopyableText
                text={`${params.channel_old}.v${params.version_old}`}
                displayText=""
              />
            </Ui.Label>

            <Ui.Dropdown
              placeholder="Select Channel"
              search
              selection
              value={params.channel_old}
              options={channelOptions}
              onChange={(_, { value }) => {
                let copy = params;
                copy.channel_old = (value as string) || "-";
                copy.version_old = `${T.findLatestVer(
                  p.allObjects,
                  copy.channel_old
                )}`;
                updateUrl(copy);
              }}
            />
          </Ui.Form.Field>
          <Ui.Form.Field inline>
            <Ui.Input
              value={params.version_old}
              type="number"
              onChange={(_, data) => {
                let copy = params;
                copy.version_old = data.value || "-";
                updateUrl(copy);
              }}
              style={{ maxWidth: "80px" }}
            />
          </Ui.Form.Field>
          <Ui.Button
            icon
            title="Go to latest available version"
            onClick={() => {
              let copy = params;
              copy.version_old = `${T.findLatestVer(
                p.allObjects,
                params.channel_old
              )}`;
              updateUrl(copy);
            }}
          >
            <Ui.Icon name="angle double up" />
          </Ui.Button>
          &nbsp; &nbsp;
          <Ui.Button
            icon
            circular
            color="black"
            title="Swap old/new"
            onClick={() => {
              let copy = params;
              [copy.version_new, copy.version_old] = [
                copy.version_old,
                copy.version_new,
              ];
              [copy.channel_new, copy.channel_old] = [
                copy.channel_old,
                copy.channel_new,
              ];
              updateUrl(copy);
            }}
          >
            <Ui.Icon name="arrow left" />
            <Ui.Icon name="arrow right" />
          </Ui.Button>
          &nbsp;
          <Ui.Form.Field inline>
            <Ui.Label color="green">
              To
              <CopyableText
                text={`${params.channel_new}.v${params.version_new}`}
                displayText=""
              />
            </Ui.Label>
            <Ui.Dropdown
              placeholder="Select Channel"
              search
              selection
              value={params.channel_new}
              options={channelOptions}
              onChange={(_, { value }) => {
                let copy = params;
                copy.channel_new = (value as string) || "-";
                copy.version_new = `${T.findLatestVer(
                  p.allObjects,
                  copy.channel_new
                )}`;
                updateUrl(copy);
              }}
            />
          </Ui.Form.Field>
          <Ui.Form.Field inline>
            <Ui.Input
              value={params.version_new}
              type="number"
              onChange={(_, data) => {
                let copy = params;
                copy.version_new = data.value || "-";
                updateUrl(copy);
              }}
              style={{ maxWidth: "80px" }}
            />
          </Ui.Form.Field>
          <Ui.Button
            icon
            title="Go to latest available version"
            onClick={() => {
              let copy = params;
              copy.version_new = `${T.findLatestVer(
                p.allObjects,
                params.channel_new
              )}`;
              updateUrl(copy);
            }}
          >
            <Ui.Icon name="angle double up" />
          </Ui.Button>
        </Ui.Form.Group>
      </Ui.Form>
    </div>
  );
};

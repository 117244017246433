import { StrictMode, FC, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import * as Ui from "semantic-ui-react";

import { createGlobalStyle } from "styled-components";
import { Channels } from "containers/Channel/Channels";
import { Channel } from "containers/Channel/Channel";
import { TopMenu } from "containers/TopMenu";
import { Login } from "containers/Login";
import { GoogleOAuthProvider, TokenResponse } from "@react-oauth/google";
import { fetchAllObjects, fetchPkgFamilyFileMap } from "api";
import * as T from "types";
import { List } from "immutable";
import { Release } from "containers/Release/Release";
import { ReleaseDiff } from "containers/ReleaseDiff";

const GOOGLE_OAUTH_CLIENT_ID =
  "700295116728-f8p5cpn9l8afm7207ucjucuclaq0dbi2.apps.googleusercontent.com";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const Todo: FC = (p) => <p>TODO</p>;

const App: FC = () => {
  const [loginInfo, setLoginInfo] = useState<TokenResponse>();
  const [allObjects, setAllObjects] = useState<T.StorageMetadataList>(List());
  const [allPkgFamilyFiles, setAllPkgFamilyFiles] =
    useState<T.PkgFamilyFilesMap>();

  // loading login data from localstorage
  useEffect(() => {
    if ("login" in localStorage && "loginExpiresAt" in localStorage) {
      const fromStorage = JSON.parse(localStorage["login"]);
      const expiresAt = JSON.parse(localStorage["loginExpiresAt"]);

      // expired?
      if (new Date().getTime() < expiresAt) {
        setLoginInfo(fromStorage);
      } else {
        localStorage.removeItem("login");
        localStorage.removeItem("loginExpiresAt");
      }
    }
  }, []);

  // writing login data to localstorage
  useEffect(() => {
    if (!loginInfo || "login" in localStorage) {
      return;
    }
    const expiresAt = new Date().getTime() + loginInfo.expires_in * 1000;
    localStorage["login"] = JSON.stringify(loginInfo);
    localStorage["loginExpiresAt"] = JSON.stringify(expiresAt);
  }, [loginInfo]);

  useEffect(() => {
    if (loginInfo?.access_token) {
      fetchAllObjects(loginInfo.access_token).then(setAllObjects);
    }
  }, [loginInfo]);

  useEffect(() => {
    if (loginInfo?.access_token) {
      fetchPkgFamilyFileMap(loginInfo?.access_token, allObjects).then(
        setAllPkgFamilyFiles
      );
    }
  }, [allObjects, loginInfo]);

  if (!loginInfo) {
    return (
      <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
        <Login info={loginInfo} setInfo={setLoginInfo} />
      </GoogleOAuthProvider>
    );
  }

  return (
    <StrictMode>
      <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
        <GlobalStyle />

        <BrowserRouter>
          <TopMenu />
          <Ui.Container style={{ marginTop: "7em" }}>
            <Login info={loginInfo} setInfo={setLoginInfo} />
            <Routes>
              {/* Channel and Release routes */}
              <Route
                path="/channels/"
                element={<Channels allObjects={allObjects} />}
              />
              <Route
                path="/channel/:channel"
                element={<Channel allObjects={allObjects} />}
              />
              <Route
                path="/release/:channel_old/:version_old/:channel_new/:version_new"
                element={
                  <ReleaseDiff
                    accessToken={loginInfo ? loginInfo.access_token : ""}
                    allObjects={allObjects}
                    pkgFamilyFiles={allPkgFamilyFiles}
                  />
                }
              />
              <Route path="/release/:channel/:version" element={<Release />} />

              {/* Package routes */}
              <Route path="/packages/" element={<Todo />} />
              <Route path="/packages/:name" element={<Todo />} />
              <Route path="/package/:name/:version" element={<Todo />} />

              {/* Misc routes */}
              <Route path="/login"></Route>

              <Route path="/" element={<Navigate replace to="/channels" />} />
            </Routes>
          </Ui.Container>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </StrictMode>
  );
};

const root = createRoot(document.getElementById("root")!);
root.render(<App />);

import React from "react";
import * as T from "types";
import * as Ui from "semantic-ui-react";
import * as Im from "immutable";
import { PackageBucketLink } from "components/BucketLink";
import { PkgPopup } from "./PkgPopup";
import { PkgDiffLink } from "./PkgDiffLink";

interface Props {
  accessToken: string;
  allObjects: T.StorageMetadataList;
  pkgFamilyFiles?: T.PkgFamilyFilesMap;
  packages_old: T.RelFilePackages;
  packages_new: T.RelFilePackages;
  showDiffColumn?: boolean;
}

export const PkgTable: React.FC<Props> = (p) => {
  if (p.packages_new.isEmpty()) {
    return <b>Empty!</b>;
  }

  return (
    <Ui.Table celled fixed>
      <Ui.Table.Header>
        <Ui.Table.Row>
          <Ui.Table.HeaderCell>Package</Ui.Table.HeaderCell>
          <Ui.Table.HeaderCell>Version</Ui.Table.HeaderCell>
          {p?.showDiffColumn && <Ui.Table.HeaderCell>Diff</Ui.Table.HeaderCell>}
        </Ui.Table.Row>
      </Ui.Table.Header>
      <Ui.Table.Body>
        {/* List unchanged, updated and newly created packages */}
        {Im.List(p.packages_new)
          .sortBy(([pkg, ver]) => pkg)
          .map(([pkg, ver_new]) => {
            const ver_old = p.packages_old.get(pkg);
            return (
              <Ui.Table.Row key={pkg}>
                <Ui.Table.Cell>
                  <PackageBucketLink name={pkg}>{pkg}</PackageBucketLink>
                </Ui.Table.Cell>
                <Ui.Table.Cell>
                  {ver_old !== ver_new && (
                    <span>
                      {ver_old ? (
                        <PkgPopup
                          color="red"
                          pkgFamilyFiles={p.pkgFamilyFiles}
                          name={pkg}
                          version={ver_old}
                        />
                      ) : (
                        <Ui.Icon name="close" />
                      )}
                      <Ui.Icon name="arrow right" />
                    </span>
                  )}
                  <PkgPopup
                    pkgFamilyFiles={p.pkgFamilyFiles}
                    name={pkg}
                    color="green"
                    version={ver_new}
                  />
                </Ui.Table.Cell>
                {p?.showDiffColumn && (
                  <Ui.Table.Cell>
                    <PkgDiffLink
                      pkg_name={pkg}
                      ver_new={ver_new}
                      ver_old={ver_old}
                    />
                  </Ui.Table.Cell>
                )}
              </Ui.Table.Row>
            );
          })}

        {/* List removed packages */}
        {Im.OrderedSet(p.packages_old.keySeq())
          .subtract(p.packages_new.keySeq())
          .map((pkg) => {
            const ver_old = p.packages_old.get(pkg);
            return (
              <Ui.Table.Row key={pkg}>
                <Ui.Table.Cell>
                  <PackageBucketLink name={pkg}>{pkg}</PackageBucketLink>
                </Ui.Table.Cell>
                <Ui.Table.Cell>
                  <PkgPopup
                    color="red"
                    pkgFamilyFiles={p.pkgFamilyFiles}
                    name={pkg}
                    version={ver_old}
                  />
                  <Ui.Icon name="arrow right" />
                  <Ui.Icon name="close" />
                </Ui.Table.Cell>
                <Ui.Table.Cell />
              </Ui.Table.Row>
            );
          })}
      </Ui.Table.Body>
    </Ui.Table>
  );
};

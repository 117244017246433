import * as Im from "immutable";

export interface IRelFilePackageList {
  [pkg: string]: number;
}

export type RelFilePackages = Im.Map<string, number>;

export interface IRelFile {
  notes: string;
  packages: RelFilePackages;
  tabletPackages: RelFilePackages;
  apiPackages: RelFilePackages;
}

export type RelFile = Im.Record<IRelFile> & Readonly<IRelFile>;

export const FRelFile = Im.Record<IRelFile>({
  notes: "",
  packages: Im.Map(),
  tabletPackages: Im.Map(),
  apiPackages: Im.Map(),
});

export interface IStorageMetadata {
  bucket: string;
  generation: string;
  etag: string;
  ms5Hask: string;
  mediaLink: string;
  name: string;
  retentionExpirationTime: string;
  selfLink: string;
  size: string;
  storageClass: string;
  timeCreated: string;
  updated: string;
  fkBaseName: string;
}

export type StorageMetadata = Im.Record<IStorageMetadata> &
  Readonly<IStorageMetadata>;

export const FStorageMetadata = Im.Record<IStorageMetadata>({
  bucket: "",
  generation: "",
  etag: "",
  ms5Hask: "",
  mediaLink: "",
  name: "",
  retentionExpirationTime: "",
  selfLink: "",
  size: "",
  storageClass: "",
  timeCreated: "",
  updated: "",
  fkBaseName: "",
});

export type StorageMetadataList = Im.List<StorageMetadata>;

// ReleaseStorageMetadata

export interface IReleaseStorageMetadata extends IStorageMetadata {
  fkChannel: string;
  fkChannelType: string;
  fkReleaseVersion: number;
  fkRelease: string;
}

export type ReleaseStorageMetadata = Im.Record<IReleaseStorageMetadata> &
  Readonly<IReleaseStorageMetadata>;

export const FReleaseStorageMetadata = Im.Record<IReleaseStorageMetadata>({
  bucket: "",
  generation: "",
  etag: "",
  ms5Hask: "",
  mediaLink: "",
  name: "",
  retentionExpirationTime: "",
  selfLink: "",
  size: "",
  storageClass: "",
  timeCreated: "",
  updated: "",
  fkBaseName: "",
  fkRelease: "",
  fkChannel: "",
  fkChannelType: "",
  fkReleaseVersion: -1,
});

// Manipulators

export const mapBaseName = (os: StorageMetadataList) => {
  return os
    .filter((o) => o.name.startsWith("sigma-system"))
    .map((o) => o.set("fkBaseName", Im.List(o.name.split("/")).last()));
};

// releaseRegex Example:
// input: sigma-system/rooftop-proxyserver-beta.v5.release
// group1: rooftop-proxyserver-beta.v5
// group2: rooftop-proxyserver-beta
// group3: beta
// group4: 5
const releaseRegex = () =>
  /(?:.*\/)?(((?:.*-)?([a-z0-9-]*)).v([0-9]+)).release/g;

export const filterReleases = (os: StorageMetadataList) =>
  os
    .filter((o) => o.name.endsWith(".release"))
    .map((o) => {
      const match = releaseRegex().exec(o.name);
      if (!match) {
        throw new Error(`Regex failed: ${o.name}`);
      }

      return FReleaseStorageMetadata({
        ...o.toJS(),
        fkRelease: match[1],
        fkChannel: match[2],
        fkChannelType: match[3],
        fkReleaseVersion: parseInt(match[4]),
      } as IReleaseStorageMetadata);
    })
    .sortBy((r) => r.name)
    .reverse();

export const findLatestVer = (os: StorageMetadataList, channel: string) => {
  const release = filterReleases(os)
    .filter((o) => o.fkChannel === channel)
    .sort()
    .last(null);

  return release ? release.fkReleaseVersion : 0;
};

export const groupChannels = (os: StorageMetadataList) => {
  return filterReleases(os)
    .groupBy((o) => o.fkChannel)
    .sortBy((g) => g.first()?.fkChannel)
    .map((g) => g.toList())
    .toList();
};

export interface IPkgFamily {
  path: string;
  repo: string;
  tag: string;
}

export type PkgFamily = Im.Record<IPkgFamily> & Readonly<IPkgFamily>;

export const FPkgFamily = Im.Record<IPkgFamily>({
  path: "",
  repo: "",
  tag: "",
});

export type PkgFamilyFile = Im.List<PkgFamily>;

export type PkgFamilyFilesMap = Im.Map<string, PkgFamilyFile>;

export const getGithubLinks = (
  pkgname: string,
  pkgversion?: number,
  files?: PkgFamilyFilesMap
) => {
  if (!files) {
    return "";
  }
  const pkgfamily = files.get(pkgname);
  if (!pkgfamily) {
    return null;
  }
  return pkgfamily.map((pkg) => {
    const tag = pkgversion ? `${pkg.tag}.v${pkgversion}` : "master";
    return `https://${pkg.repo}tree/${tag}/${pkg.path}`;
  });
};

export interface IPkgMetadata {
  notes: string;
}

export type PkgMetadata = Im.Record<IPkgMetadata> & Readonly<IPkgMetadata>;

export const FPkgMetadata = Im.Record<IPkgMetadata>({
  notes: "",
});

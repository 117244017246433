import * as React from "react";
import * as Ui from "semantic-ui-react";

interface Props {
  prefix?: string;
  fileName?: string;
}

const confluenceLink =
  "https://fotokite.atlassian.net/wiki/spaces/SWENG/pages/1193607903/Package+manager+Channels";

const fileName2BucketObjLink = (fileName: string) =>
  `https://console.cloud.google.com/storage/browser/_details/software-repository/${fileName}?project=fotokite-asix`;

const BASE_URL =
  "https://console.cloud.google.com/storage/browser/software-repository/sigma-system;tab=objects?project=fotokite-asix&prefix=";

const prefix2BucketLink = (prefix?: string) => `${BASE_URL}${prefix || ""}`;

export const BucketLink: React.FC<Props> = (p) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={
      p.fileName
        ? fileName2BucketObjLink(p.fileName)
        : prefix2BucketLink(p.prefix)
    }
  >
    <Ui.Button
      content={p.fileName ? p.fileName + " in bucket" : "Backing Bucket"}
      icon="cloud"
      labelPosition="left"
      primary
    />
  </a>
);

export const ConfluenceChannelsLink: React.FC<Props> = (p) => (
  <a target="_blank" rel="noopener noreferrer" href={confluenceLink}>
    <Ui.Button
      content="Description of Channels"
      icon="file"
      labelPosition="left"
      primary
    />
  </a>
);

interface PackageBucketLinkProps {
  name: string;
  version?: number;
  children: React.ReactNode;
}

export const PackageBucketLink: React.FC<PackageBucketLinkProps> = (p) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={prefix2BucketLink(`${p.name}.${p.version ? `v${p.version}` : ""}`)}
  >
    {p.children}
  </a>
);

import * as React from "react";
import * as Ui from "semantic-ui-react";
import {
  TokenResponse,
  useGoogleLogin,
  NonOAuthError,
} from "@react-oauth/google";

interface Props {
  info?: TokenResponse;
  setInfo: (v: TokenResponse) => void;
}

export const Login: React.FC<Props> = (p) => {
  const login = useGoogleLogin({
    onSuccess: (
      tokenResponse: Omit<
        TokenResponse,
        "error" | "error_description" | "error_uri"
      >
    ) => {
      console.log("Login success:", tokenResponse);
      p.setInfo(tokenResponse);
    },
    onError: (
      errorResponse: Pick<
        TokenResponse,
        "error" | "error_description" | "error_uri"
      >
    ) => {
      console.log("Login failure (Oauth):", errorResponse);
    },
    onNonOAuthError: (nonOAuthError: NonOAuthError) => {
      console.log("Login failure (Non Oauth):", nonOAuthError);
    },
    // https://developers.google.com/identity/protocols/oauth2/scopes#storage
    scope: "https://www.googleapis.com/auth/devstorage.read_only",
  });

  if (p.info) {
    return null;
  }

  return (
    <Ui.Message color="blue">
      <button onClick={() => login()}>Sign in with Google 🚀</button>
    </Ui.Message>
  );
};

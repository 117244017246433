import { FC, useState } from "react";
import * as Ui from "semantic-ui-react";

interface Props {
  displayText?: string;
  text: string;
  iconLocation?: "left" | "right";
}

export const CopyableText: FC<Props> = (p) => {
  const [copied, setCopied] = useState(false);

  const handleClick = (event: any) => {
    // abuse the icon element as a copy-source
    event.target.innerHTML = p.text;
    const selection = window.getSelection();
    if (selection !== null) {
      selection.selectAllChildren(event.target);
      document.execCommand("copy");
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
    event.target.innerHTML = "";
  };

  return (
    <span
      style={{
        display: "flex",
        flexDirection: p?.iconLocation === "left" ? "row-reverse" : "row",
      }}
    >
      {p.displayText !== undefined ? p.displayText : p.text}
      &nbsp;
      {copied && (
        <Ui.Icon style={{ cursor: "pointer" }} color="green" name="checkmark" />
      )}
      {!copied && (
        <Ui.Icon
          onClick={handleClick}
          style={{ cursor: "pointer" }}
          name="copy outline"
        />
      )}
    </span>
  );
};

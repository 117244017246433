import * as React from "react";
import * as Ui from "semantic-ui-react";

interface Props {
  data: object;
}

export const MapViz: React.FC<Props> = (p) => {
  if (!p.data) {
    return null;
  }

  if (Array.isArray(p.data)) {
    let obj = {} as any;
    p.data.forEach((d, i) => {
      obj[i] = d;
    });
    return <MapViz data={obj} />;
  }

  if (typeof p.data === "object") {
    return (
      <Ui.Table celled>
        <Ui.Table.Body>
          {Object.entries(p.data).map(([key, value]) => (
            <Ui.Table.Row key={key}>
              <Ui.Table.Cell>{key}</Ui.Table.Cell>
              <Ui.Table.Cell>
                <MapViz data={value} />
              </Ui.Table.Cell>
            </Ui.Table.Row>
          ))}
        </Ui.Table.Body>
      </Ui.Table>
    );
  }

  return <p>{p.data}</p>;
};

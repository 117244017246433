import * as React from "react";
import * as Ui from "semantic-ui-react";
import * as T from "types";
import { CopyableText } from "./CopyableText";

const BASE_URL =
  "https://console.cloud.google.com/storage/browser/software-repository/sigma-system;tab=objects?project=fotokite-asix&prefix=";

const prefix2BucketLink = (prefix?: string) => `${BASE_URL}${prefix || ""}`;

interface PkgPopupProps {
  name: string;
  version?: number;
  pkgFamilyFiles?: T.PkgFamilyFilesMap;
  color?: Ui.SemanticCOLORS;
}

export const PkgPopup: React.FC<PkgPopupProps> = (p) => {
  const [open, setOpen] = React.useState(false);
  const githubLinks = T.getGithubLinks(p.name, p.version, p.pkgFamilyFiles);
  return (
    <Ui.Popup
      open={open}
      on="click"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Ui.Label color={p.color}>
          <CopyableText
            text={`${p.name}.v${p.version}`}
            displayText={`${p.version}`}
          />
        </Ui.Label>
      }
      content={
        <div>
          <b>
            <CopyableText text={`${p.name}.v${p.version}`} />
          </b>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={prefix2BucketLink(
                  `${p.name}.${p.version ? `v${p.version}` : ""}`
                )}
              >
                bucket
              </a>
            </li>
            {githubLinks &&
              githubLinks.map((l) => (
                <li key={l}>
                  <a target="_blank" rel="noopener noreferrer" href={l}>
                    {l.split("/")[4]}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      }
    />
  );
};

import * as React from "react";
import * as T from "types";
import * as Ui from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { BucketLink } from "components/BucketLink";

interface Props {
  allObjects: T.StorageMetadataList;
}

interface UrlParams {
  channel: string;
}

export const Channel: React.FC<Props> = (p) => {
  const params = useParams() as any as UrlParams;
  return (
    <div>
      <BucketLink prefix={`${params.channel}.v`} />
      <Ui.Item.Group>
        {T.filterReleases(p.allObjects)
          .filter((r) => r.fkChannel === params.channel)
          .sortBy((rel) => rel.fkReleaseVersion)
          .reverse()
          .map((rel) => (
            <ChannelItem key={rel.fkReleaseVersion} release={rel} />
          ))}
      </Ui.Item.Group>
    </div>
  );
};

export const ChannelItem: React.FC<{
  release: T.ReleaseStorageMetadata;
}> = (p) => {
  return (
    <Ui.Item>
      <Ui.Item.Content>
        {p.release.timeCreated}{" "}
        <Link
          to={`/release/${p.release.fkChannel}/${p.release.fkReleaseVersion}`}
        >
          {p.release.fkRelease}
        </Link>{" "}
      </Ui.Item.Content>
    </Ui.Item>
  );
};

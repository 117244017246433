import * as T from "types";
import { List, Map } from "immutable";

const BASE_URL =
  "https://storage.googleapis.com/storage/v1/b/software-repository";

async function authenticatedFetch(
  token: string,
  url: string,
  options?: object
) {
  const base_options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await fetch(url, { ...base_options, ...options });
}

async function fetchObjectsPartial(authToken: string, nextPage?: string) {
  const path =
    `${BASE_URL}/o?maxResults=10000` +
    (!nextPage ? "" : `&pageToken=${nextPage}`);
  const res = await authenticatedFetch(authToken, path);
  if (!res.ok) {
    return null;
  }

  const respJ = await res.json();
  return respJ;
}

export async function fetchAllObjects(authToken: string) {
  let objects = [] as object[];
  let res = await fetchObjectsPartial(authToken);
  objects = res["items"];

  while ("nextPageToken" in res) {
    res = await fetchObjectsPartial(authToken, res["nextPageToken"]);
    objects = objects.concat(res["items"]);
  }

  let immutableObjects = List(objects.map(T.FStorageMetadata));
  return T.mapBaseName(immutableObjects);
}

export async function fetchRawObjectContent(
  authToken: string,
  obj: T.StorageMetadata | T.ReleaseStorageMetadata
) {
  const res = await authenticatedFetch(authToken, `${obj.selfLink}?alt=media`);
  return res.json();
}

export async function fetchObjectContent(
  authToken: string,
  obj: T.StorageMetadata | T.ReleaseStorageMetadata
) {
  const raw = await fetchRawObjectContent(authToken, obj);
  return T.FRelFile({
    notes: raw.notes,
    packages: Map(raw.packages),
    tabletPackages: Map(raw.tabletPackages),
    apiPackages: Map(raw.apiPackages),
  });
}

export async function fetchPkgMetadataContent(
  authToken: string,
  obj: T.StorageMetadata | T.ReleaseStorageMetadata
) {
  const raw = await fetchRawObjectContent(authToken, obj);
  return T.FPkgMetadata({
    notes: raw.notes,
  });
}

export async function fetchPkgFamilyFileMap(
  authToken: string,
  allObjects: T.StorageMetadataList
) {
  let resultMap = Map<string, T.PkgFamilyFile>();

  const metas = allObjects.filter((o) =>
    o.name.endsWith("pkg_family_metadata.json")
  );

  const requests = metas.map((meta) => {
    const name = meta.name.split("/")[1].split(".")[0];
    return fetchRawObjectContent(authToken, meta).then((response) => ({
      name,
      response,
    }));
  });
  const responses = await Promise.all(requests);

  // console.log(responses)

  for (const { name, response } of responses) {
    let l = List();
    for (const rawPkgFamily of response) {
      l = l.push(T.FPkgFamily(rawPkgFamily));
    }

    resultMap = resultMap.set(name, l as T.PkgFamilyFile);
  }

  return resultMap;
}

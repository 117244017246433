import * as React from "react";
import * as Ui from "semantic-ui-react";
import { Link } from "react-router-dom";

interface Props {}

export const TopMenu: React.FC<Props> = (p) => {
  return (
    <Ui.Menu fixed="top" inverted>
      <Ui.Menu.Item header>
        <Link to="/">PackageAdmin</Link>
      </Ui.Menu.Item>
      <Ui.Menu.Item>
        <Link to="/channels">Channels</Link>
      </Ui.Menu.Item>
      <Ui.Menu.Item>
        <Link to="/packages">Packages</Link>
      </Ui.Menu.Item>
    </Ui.Menu>
  );
};

import { FC } from "react";
import * as Ui from "semantic-ui-react";
import { CopyableText } from "./CopyableText";

interface IProps {
  pkg_name: string;
  ver_old: number | undefined;
  ver_new: number | undefined;
}

export const PkgDiffLink: FC<IProps> = (p) => {
  if (!p.ver_old || !p.ver_new || p.ver_new === p.ver_old) {
    return null;
  }

  return (
    <>
      <Ui.Message info size="mini">
        <Ui.Message.Content
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CopyableText
            text={`fk-pkg-diff ${p.pkg_name} ${p.ver_old} ${p.ver_new}`}
            iconLocation="left"
          />
          <a
            href="https://fotokite.atlassian.net/wiki/spaces/SWR/pages/420446257/HOWTO+Create+a+package+for+Fotokite+Sigma+package+manager#View-and-compare-release-channels"
            target="_blank"
            style={{ fontSize: "13px" }}
          >
            <Ui.Icon
              style={{ cursor: "pointer" }}
              name="question circle outline"
            />
          </a>
        </Ui.Message.Content>
      </Ui.Message>
    </>
  );
};
